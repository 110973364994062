import brandLogo from './brand-logo.svg';
import arrow from './arrow.svg';
import envelope from './envelope.svg';
import './App.css';

function App() {
  return (
    <div className="h-screen">
      <div className='container portrait:h-screen sm:h-screen sm:landscape:h-auto lg:landscape:h-screen sm:landscape:py-6 flex flex-col justify-between py-6'>
       <nav><img src={brandLogo} alt="logo" /></nav>
        <section className='my-6 md:my-0 lg:py-0 lg:pr-6 h-main-content landscape:py-16 sm:landscape:py-16'>
          <h1 className='text-white text-xl lg:text-xxl mb-6'>We build long-term holding companies with exceptional entrepreneurs</h1>
          <p className='text-white text-base md:text-lg mt-6'>Compounding Labs backs quality people to invest in recurring revenue businesses in niche markets. Distinct from a traditional investment firm, we are defined by a unique personal capital base, a multi-decade time horizon, and a highly entrepreneurial ethos.</p> 
        </section> 
        <a href="#pageAnchor"> <img src={arrow} className="self-start h-20 lg:h-24" alt="arrow" /></a> 
      </div>
      <div className='bg-white mt-10'>
        <div className='container'>
          <div className='gap-4 py-6 md:py-12 lg:columns-4' id="pageAnchor">
            <div className='col-row mt-8 mb-8 md:mt-0'>
              <h2 className='col-header'>FOCUS</h2>
              <p className='text-base'>We focus on businesses with recurring or predictable revenue, low single-digit customer attrition, long-term secular growth tailwinds, significant cash flow generation, and potential for opportunistic consolidation.</p>
            </div>
            <div className='col-row mb-8'>
              <h2 className='col-header'>STRATEGY</h2>
              <p className='text-base'>We believe a relentless focus on equity efficiency, ruthlessly pragmatic resource allocation, and a commitment to continuous improvement maximize our odds of long-term value creation. Accordingly, we partner with CEOs who are learning machines with long runways.</p>
            </div>
            <div className='col-row mb-8'>
              <h2 className='col-header'>APPROACH</h2>
              <p className='text-base'>We seek to deserve great partners by being great partners. Leveraging our collective experience and networks, we actively go to work for our entrepreneurs to help them realize their full potential. We dream big, prioritize people, and compete to win.</p>
            </div>
            <div className='col-row mb-8'>
              <h2 className='col-header'>VALUES</h2>
              <p className='text-base'>With a time horizon measured in decades, we are patiently playing the long game. We care deeply about what we do and who we do it with. We accomplish more with less. We aim to make thoughtful, data-driven decisions. We don’t make small bets – we’re all in.</p>
            </div>
          </div>
        </div>
      </div>
      <div className='flex justify-center py-10'>
        <a href="mailto:kent@compoundinglabs.com" target="_blank" rel="noreferrer"><img src={envelope} className="height-envelope" alt="envelope" /></a>
      </div>
    </div> // end container
  );
}

export default App;
